import { randomNumber, validUploadFile } 	from '../../constants/config';

export default {
    state: () => ({
        cart						: { products: [], details: null },
        cart_save_later				: { products: [], details: null },
        advance_preorder	        : { products: [], details: null },
        complete_preorder           : { valid: false, products: [], details: null, customer: null },
        coupon                      : null,
        product                     : null,
        collection                  : null,
        list_products               : null,
        filters_collection          : [],
        searchProducts              : [],
        collectionHome              : [],
        priceCatalog                : [],
        listAddBestSellers			: [],
        notification_products		: [],
        url_download_price_catalog  : "",
        url_download_cart_file		: "",
        url_download_checkout_file	: "",
        products_recently_viewed    : []
    }),
    mutations: {
        setCart(state, payload) {
            state.cart = payload
        },
        setCartSaveLater(state, payload) {
            state.cart_save_later = payload
        },
        setAdvancePreorder(state, payload) {
            state.advance_preorder = payload
        },
        setCompletePreorder(state, payload) {
            state.complete_preorder = payload
        },
        setCoupon(state, payload) {
            state.coupon = payload
        },
        setCollection(state, payload) {
            state.collection = payload
        },
        setProduct(state, payload) {
            state.product = payload
        },
        setProducts(state, payload) {
            state.list_products = payload
        },
        setFiltersCollection(state, payload) {
            state.filters_collection = payload
        },
        setSearchProduct(state, payload) {
            state.searchProducts = payload
        },
        setListAddBestSellers(state, payload) {
            state.listAddBestSellers = payload; 
        },
        setCollectionHome(state, payload) {
            state.collectionHome = payload
        },
        setPriceCatalog(state, payload) {
            state.priceCatalog = payload
        },
        setNotificationProductCart(state, payload) {
            state.notification_products = payload; 
        },
        setDownloadPriceCatalog(state, payload) {
            state.url_download_price_catalog = payload
        },
        setDownloadCartFile(state, payload) { 
            state.url_download_cart_file = payload; 
        },
        setProductsRecentlyViewed(state, payload) {
            state.products_recently_viewed = payload;
        }
    },
    getters: {
        getterCart						: state => state.cart,
        getterCartSaveLater				: state => state.cart_save_later,
        getterAdvancePreorder	        : state => state.advance_preorder,
        getterCompletePreorder          : state => state.complete_preorder,
        getterCoupon                    : state => state.coupon,
        getterCollection                : state => state.collection,
        getterProduct                   : state => state.product,
        getterProducts                  : state => state.list_products,
        getterFiltersCollection         : state => state.filters_collection,
        getterSearchProduct             : state => state.searchProducts,
        getterCollectionHome            : state => state.collectionHome,
        getterListAddBestSellers		: state => state.listAddBestSellers,
        getterPriceCatalog              : state => state.priceCatalog,
        getterNotificationProductCart	: state => state.notification_products,
        getterDownloadPriceCatalog      : state => state.url_download_price_catalog,
        getterDownloadCartFile			: state => state.url_download_cart_file,
        getterProductsRecentlyViewed    : state => state.products_recently_viewed
    },
    actions: {
        // =================================================================
        //  CART ACTIONS
        // =================================================================
        async actionCart({commit}, payload){
            
            await this.$hefesto.$put(`/carts/${ payload.action }`, { products: JSON.stringify(payload.products) } ).then( (axios_response) => { 
                
                const { data, app_version } = axios_response;
                commit('setCoupon', data.coupon);
                commit('setCart', { products: data.products, details: data.details_cart });
                commit('setCartSaveLater', { products: data.save_later, details: data.details_cart_save_later });
                if( payload.action == 'add' ){
                    commit('setNotificationProductCart', data.update_product_cart);
                }
            }).catch( (axios_error) => {
                
                commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
            });
        },
        async getCart({commit}, payload){
            
            await this.$hefesto.$get(`/carts/products`).then( (axios_response) => { 
                
                const { data, app_version } = axios_response;
                commit('setCoupon', data.coupon);
                commit('setCart', { products: data.products, details: data.details_cart });
                commit('setCartSaveLater', { products: data.save_later, details: data.details_cart_save_later });
            }).catch( (axios_error) => {
                
                commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
            });
        },
        async getAdvancePreorder({commit}, payload){
            
            await this.$hefesto.$post(`/carts/advance-preorder`, payload).then( (axios_response) => { 
                
                const { data } = axios_response;
                commit('setCoupon', data.coupon);
                commit('setAdvancePreorder', { products: data.products, details: data.details });
            }).catch( (axios_error) => {
                
                commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
            });
        },
        async getCompletePreorder({commit}, payload){
            
            await this.$hefesto.$post(`/carts/complete-preorder`, payload).then( (axios_response) => { 
                try {
                    
                    const { data }  = axios_response;
                    let data_user 	= {
                        id				: data.user.id,
                        name			: data.user.name,
                        email			: data.user.email,
                        profile_image	: `${ data.user.profile_image }?v=${ randomNumber() }`
                    };
                    this.$cookies.set('token-app'	, data.user.token, { path: '/', maxAge: 60 * 60 * 24 * 7 });
                    this.$cookies.set('user-store'	, JSON.stringify( data_user ), { path: '/', maxAge: 60 * 60 * 24 * 7 });

                    commit('products/setCoupon'         , null, { root: true });
                    commit('customers/setUser'          , data.user, { root: true });
                    commit('customers/setProfileImage'  , `${ data.user.profile_image }?v=${ randomNumber() }`, { root: true } );
                    commit('customers/setLogin'         , true, { root: true });
                    commit('setCompletePreorder'        , { preorder: payload.id, valid: true, products: data.products, details: data.details });
                } catch (error) {
                    console.log( 1, error );
                }
				
            }).catch( (axios_error) => {
                
                commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
            });
        },
        async downloadCartFile({commit}, payload){
            
            await this.$hefesto.$get(`/carts/download-cart-file?new_file=${ payload.new_file }&origin=${ payload.origin }&customer=${ payload.customer }`).then( (axios_response) => { 
                
                const { data } = axios_response;
                commit('setDownloadCartFile', "");
                commit('setDownloadCartFile', data.url);
            }).catch( (axios_error) => {
                
                commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
            });
        },
        async deleteCartFile({commit}, payload){
            
            await this.$hefesto.$post(`/carts/delete-cart-file`,{ url_file: payload.url_file }).then( (axios_response) => { 
                
                commit('setDownloadCartFile', "");
            }).catch( (axios_error) => {
                
                commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
            })
        },
        async uploadCartFile({commit, state}, payload){
            
            let result = validUploadFile( payload.file, payload.input_name, payload.max_size, payload.old_file_path, payload.extra_data );
            
            if( result.success ){
                
                await this.$hefesto.$post('/carts/charge-products', result.data ).then( (axios_response) => { 
                    
                    const { data } = axios_response;
                    if(state.cart.details.total === data.details_cart.total) {
                        
                        commit('setNotification', { content: { type: 'request' }, type: 'error', data: { title: 'Error: Charge Product Cart', message: 'The products do not have the conditions to be added to the cart', app_version: this.$cookies.get('app-version') } });
                    } 
                    else {
                        
                        commit('setNotification', { content: { type: 'request' }, type: 'info', data: { title: 'Info: Charge Product Cart', message: `In your cart there are now ${ data.details_cart.count } products`, app_version: this.$cookies.get('app-version') } });
                    }
                    commit('setCart', { products: data.products, details: data.details_cart });
                    commit('setCoupon', data.coupon);
                }).catch( (axios_error) => {
                    
                    commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
                });
            }
            else{
                
                commit('setNotification', { content: { type: 'request' }, type: 'error', data: { title: result.title, message: result.message, app_version: this.$cookies.get('app-version') } });
            }
        },
        // =================================================================
        //  COUPON ACTIONS
        // =================================================================
        async addCouponCart({commit}, payload){
            
            await this.$hefesto.$put('/carts/add-coupon-code', {coupon: payload.coupon_id}).then( (axios_response) => { 
                
                const { data } = axios_response;
                commit('setCoupon', data.coupon);
                commit('setCart', { products: data.products, details: data.details_cart });
            }).catch( (axios_error) => {
                
                commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
            });
        },
        async getCoupon({ commit }, payload) {
            
            await this.$hefesto.$post(`/app-agent/coupon/applicable`, payload.data).then( (axios_response) => { 
                
                const { data, title, message } = axios_response;
                commit('setCoupon', data.coupon);
                if( !data.coupon ){
                    
                    commit('setCoupon', { name: null });
                    commit('setNotification', { content: { type: 'request' }, type: 'error', data: { title: "Failed Coupon Appli", message: "Coupon not exist", app_version: this.$cookies.get('app-version') } });
                }
                else{
                    
                    commit('setNotification', { content: { type: 'request' }, type: 'success', data: { title: title, message: message } });
                }
            }).catch( (axios_error) => {
                
                commit('setCoupon', { name: null });
                commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
            })
        },
        // =================================================================
        //  COLLECTION ACTIONS
        // =================================================================
        async updateFilterCollection({ commit }, payload){
            
            commit('setFiltersCollection', payload );
        },
        async resetCollection({ commit }, payload){
            
            commit('setCollection', null );
        },
        async getCollection({ commit }, payload) {
            
            const { handle_collection, page, limit, per_page, filters, sort, sort_direction, price, brands, product_categories, variants, search, id_catalog, email_catalog } = payload;
            let body_request = {
                id_catalog      : id_catalog,
                email_catalog   : email_catalog,
                page            : page,
                get_filters     : filters,
                limit           : limit,
                per_page        : per_page,
                sort            : sort,
                sort_direction  : sort_direction,
                filters         : {
                    price           : price || null, // { min_price: 0, max_price: 0 },
                    search          : search || "",
                    brands          : brands ? JSON.stringify(brands) : "[]",
                    product_categories   : product_categories ? JSON.stringify(product_categories) :  "[]",
                    variants        : variants ? JSON.stringify(variants) : "[]"
                }
            };
            await this.$hefesto.$post(`/collections/${handle_collection}`, body_request).then( (axios_response) => { 
                
                const { data, success } = axios_response;
                if( success ){
                    
                    commit('setCollection', data);
                }
                else{
                    
                    commit('setCollection', data);
                    commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_response });
                }
            }).catch( (axios_error) => {
                
                commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
            })
        },
        async searchProduct({commit}, payload) {
            
            commit('setSearchProduct', []);
            await this.$hefesto.$get(`/products/quick-search?page=1&limit=0`).then( (axios_response) => { 
                
                const { data } = axios_response;
                commit('setSearchProduct', data);
            }).catch( (axios_error) => {
                
                commit('setSearchProduct', []);
            });
        },
        async getCollectionHome({commit}, payload) {
            
            await this.$hefesto.$get(`/products/collection-home`).then( (axios_response) => {
                
                const { data } = axios_response;
                commit('setCollectionHome', data);
            }).catch( (axios_error) => {
                
                commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
            });
        },
        async getListAddBestSellers({commit}, payload){
            
            await this.$hefesto.$get(`/carts/list-add-best-sellers?product_origin=${payload.product_origin}`, payload).then( (axios_response) => { 
                
                const { data } = axios_response;
                commit('setListAddBestSellers', data);
            }).catch( (axios_error) => {
                
                commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
            });
        },
        // =================================================================
        //  PRODUCT ACTIONS
        // =================================================================
        async getProduct({ commit }, payload) {
            
            let handle_collection = payload.handle_collection ? `handle_collection=${ payload.handle_collection }` : '';
            let customer_id = payload.customer_id ? `customer=${ payload.customer_id }` : '';
            
            if( handle_collection !== '' ) {
                
                handle_collection = `?${ handle_collection }`;
            }
            if( handle_collection === '' && customer_id !== '' ) {
                
                customer_id = `?${ customer_id }`;
            }
            else if( handle_collection !== '' && customer_id !== '' ) {
                
                customer_id = `&${ customer_id }`;
            }
            await this.$hefesto.$get( `/products/${ payload.handle }/details${ handle_collection }${ customer_id }` ).then( (axios_response) => { 
                
                const { data, app_version } = axios_response;
                commit('setProduct', data);
            }).catch( (axios_error) => {
                
                commit('setProduct', "not-found");
                commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
            });
        },
        async getProductViewed({commit}, payload) {
            
            await this.$hefesto.$post(`/products/recently-viewed`, payload).then( (axios_response) => {
                
                const { data } = axios_response;
                commit('setProductsRecentlyViewed', data);
            }).catch( (axios_error) => {
                
                commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
            });
        },
        // =================================================================
        //  PRICE CATALOG ACTIONS
        // =================================================================
        async getPriceCatalog({commit}, payload) {
            
            await this.$hefesto.$get(`/products/price-catalog`).then( (axios_response) => { 
                
                const { data } = axios_response;
                commit('setPriceCatalog', data);
            }).catch( (axios_error) => {
                
                commit('setPriceCatalog', [])
            })
        },
        async downloadPriceCatalog({commit}, payload) {
            
            await this.$hefesto.$get(`/products/download-price-catalog-file`).then( (axios_response) => { 
                
                const { data } = axios_response;
                commit('setDownloadPriceCatalog', "");
                commit('setDownloadPriceCatalog', data.url);
                commit('setNotification', { content: { type: 'request' }, type: 'success', data: data });
            }).catch( (axios_error) => {
                
                commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
            })
        },
        async deletePriceCatalog({commit}, payload) {
            
            await this.$hefesto.$get(`/products/delete-price-catalog-file`).then( (axios_response) => { 
                
                commit('setDownloadPriceCatalog', "");
            }).catch( (axios_error) => {
                
                commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
            })
        },
    }
}
